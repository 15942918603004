import axios from "../config/axios-config";
/* eslint-disable import/prefer-default-export */

const fetchOrphanAccounts = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post("/api/hms/getorphanaccounts", data);
  const responseData = await response.data;
  return { results: responseData };
};

const fetchGtmParents = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post("/api/hms/getgtmparents", data);
  const responseData = await response.data;
  return responseData;
};

const associateOrphanAccount = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/hms/associateorphanaccount`, data);
  const responseData = await response.data;
  return responseData;
};

const saveGtmParentAndSub = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/hms/creategtmparentsub`, data);
  const responseData = await response.data;
  return responseData;
};

const fetchWorkflowsByStatus = async (status, userId, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.get(
    `/api/hms/getworkflows?status=${status}&userId=${userId}`
  );
  const responseData = await response.data;
  return responseData;
};

const fetchWorkflowDetails = async (id, userId, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.get(
    `/api/hms/getworkflowdetails/${id}?userId=${userId}`
  );
  const responseData = await response.data;
  return responseData;
};

// const getNodeDetails = async (data, token) => {
//   axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
//   const baseUrl = `/api/hms/getDetailsByCamId/`;
//   const url = baseUrl + data;
//   const response = await axios.get(url);
//   const responseData = await response.data;
//   return responseData;
// };

const saveReviewedChanges = async (workflowId, data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.put(
    `/api/hms/saveworkflowchanges/${workflowId}`,
    data
  );
  const responseStatus = await response.status;
  return responseStatus;
};

const fetchAccountById = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/hms/gethierarchydetails`, data);
  const responseData = await response.data.hierarchy;
  return responseData;
};

const proposeHierarchyChange = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post("/api/hms/proposechange", data);
  const responseData = await response.data;
  return responseData;
};

const fetchHierarchyTypes = async (token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.get(`/api/hms/gethierarchynames`);
  const responseData = await response.data;
  return responseData;
};

const searchHMS = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/hms/searchhms`, data);
  const responseData = await response.data;
  return responseData;
};

const cloneHierarchy = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/hms/clone`, data);
  const responseData = await response.data;
  return responseData;
};

const fetchGTMHierarchyTypes = async (token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.get(`/api/hms/getgtmhierarchynames`);
  const responseData = await response.data;
  return responseData;
};

const createNewGTMHierarchy = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/hms/createhierarchy`, data);
  const responseData = await response.data;
  return responseData;
};

const getNodeHistory = async (subId, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/hms/getnodehistory/${subId}`);
  let responseData = await response.data;
  responseData = responseData.map((obj) => ({
    ...obj,
    id: obj.startTime + obj.endTime,
  }));
  return responseData;
};

const revertNodeHistory = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/hms/revert`, data);
  const responseData = await response.data;
  return responseData;
};

const removeNode = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/hms/removenode`, data);
  const responseData = await response.data;
  return responseData;
};

const moveNode = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/hms/movenode`, data);
  const responseData = await response.data;
  return responseData;
};

const moveNodes = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/hms/movenodes`, data);
  const responseData = await response.data;
  return responseData;
};

const promoteNode = async (data, token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  const response = await axios.post(`/api/hms/promotenode`, data);
  const responseData = await response.data;
  return responseData;
};

export const hierarchyApi = {
  fetchOrphanAccounts,
  fetchGtmParents,
  associateOrphanAccount,
  saveGtmParentAndSub,
  fetchWorkflowsByStatus,
  fetchWorkflowDetails,
  saveReviewedChanges,
  fetchAccountById,
  proposeHierarchyChange,
  fetchHierarchyTypes,
  searchHMS,
  cloneHierarchy,
  fetchGTMHierarchyTypes,
  createNewGTMHierarchy,
  getNodeHistory,
  revertNodeHistory,
  removeNode,
  moveNode,
  moveNodes,
  promoteNode,
};
